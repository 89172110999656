
import { Sprite } from '~/api/src/models/Sprite';

export default {
  name: 'SpriteCollection',
  computed: {
    all() {
      return Sprite.Name.options;
    },
  },
};
